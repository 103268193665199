@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import 'npm-kit-ripple/index.css';
    @import "../ui/vendors/fancybox.css";
    @import 'swiper/css';
}

@import '@qpokychuk/fonts/ibm-plex-serif/index.css';
@import '@qpokychuk/fonts/inter/index.css';
@import '../ui/ui-core.scss';
@import '../scss/frontend--style.scss';