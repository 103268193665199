
.grid.swiper .swiper-wrapper {
  flex-wrap      : nowrap;

}

.swiper {
  user-select: none;
}

img.swiper-lazy{
  transition: .2s ease;
}

img.swiper-lazy:not(.swiper-lazy-loaded){
  opacity: 0;
}

.swiper-button-disabled {
  opacity       : .4;
  pointer-events: none;
}

.swiper-slide {
  align-self: stretch;
  height    : auto;
}

@layer components{

  .slider {
    position: relative;

    .swiper {
      @media (max-width:theme('screens.md')) {
        width: calc(100% + 30px);
        margin-left: -15px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .slider-prev,
  .slider-next {
    $arrowLg1: $lg + (2 * (15px + 60px - 24px));
    position: absolute !important;
    top     : calc(50% - var(--size) / 2);
    z-index : 3;
    
  }

  .slider-next {
    $arrowLg: $lg + (2 * (24px + 60px + 24px));
    left    : calc(100% + 24px);
  
  
    @media(max-width:$arrowLg) {
      left: calc(100% - 24px);
    }
  
    @media(max-width:820px) {
      right: -12px;
      left: auto;
    }
  }
  
  
  .slider-prev {
    $arrowLg: $lg + (2 * (24px + 60px + 24px));
    right   : calc(100% + 24px);
  
    @media(max-width:$arrowLg) {
      right: calc(100% - 24px);
    }
  
    @media(max-width:820px)  {
      right: auto;
      left: -12px;
    }
  }
}




.slider--overwrap {
	@media(max-width:$md) {
		width        : calc(100% + 30px);
		overflow     : hidden;
		margin-right : -15px;
		margin-left  : -15px;
		padding-right: 15px;
		padding-left : 15px;
	}

	.swiper {
		@media(max-width:$md) {
			overflow: visible;
		}
	}
}