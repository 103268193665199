@import '../ui/scss/_variables.scss';

img {
	pointer-events: none;
	user-select   : none;
}

:root {
	--bg1: #E9EAEC;
	--bg2: #F4F5F6;
	--bg3: #FFFFFF;

	--spacing: 4px;

	--radius-small : 4px;
	--radius-middle: 8px;
	--radius-large : 12px;

	--media-lg: #{$lg};
	--media-md: #{$md};
	--media-sm: #{$sm};

	--font-base: 'Inter', Arial, Helvetica, sans-serif;
	--font-alt : 'IBM Plex Serif', Arial, Helvetica, sans-serif;

	--zi-l1: 321;
	--zi-l2: 322;
	--zi-l3: 323;
	--zi-l4: 324;
	--zi-l5: 325;
	--zi-l6: 326;
	--zi-l7: 327;
	--zi-l8: 328;
	--zi-l9: 329;
}

[data-theme="dark"] {

	--bg1: #181818;
	--bg2: #282828;
	--bg3: #323232;
}

body {
	display       : flex;
	flex-direction: column;
	min-height    : 100vh;
	background    : var(--bg3);
	color         : theme('colors.gray.DEFAULT');

	* {
		font-family: var(--font-base);
	}

}

.smooth-content {
	will-change: transform;
}

// loaders
body:not(.loaded) {
	overflow: hidden;

	// @media (min-width:theme('screens.lg')) {
	// 	padding-right: 15px;

	// }
}

.loader {
	display        : flex;
	align-items    : center;
	justify-content: center;
	position       : fixed;
	inset          : 0;
	transition     : .5s;
	background     : #fff;
	z-index        : var(--zi-l6);
}

body.loaded .loader {
	visibility    : hidden;
	opacity       : 0;
	pointer-events: none;
}



.btn {
	&-whitebg {
		box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	}

	&-icon {
		min-width: var(--size);
	}

	&-fill {
		background: linear-gradient(274.27deg, #FF6218 0%, #FB8110 24.5%, #FF810D 67.7%, #FF6218 100%);

		&:hover {
			background: linear-gradient(274.27deg, #D55B22 0%, #DF8028 24.5%, #DF8028 67.7%, #D55B22 100%);
		}

		&:active {
			background: linear-gradient(274.27deg, #B63A00 0%, #DF5428 24.5%, #DF5428 67.7%, #B63A00 100%);
		}

		&:focus:not(:active) {
			background: linear-gradient(274.27deg, #FF6218 0%, #FB8110 24.5%, #FF810D 67.7%, #FF6218 100%);
			border    : none;
			box-shadow: 0 0 0 3px #D16D11;
		}

		&:not(.btn-icon) {
			box-shadow: 0 2px 0 #B95F20;

			&:hover {
				box-shadow: 0 2px 0 #B95F20;
			}

			&:active {
				box-shadow: 0 0 0 #B95F20;
			}

		}
	}

	&-contur {
		background: none !important;
		border    : 1px solid rgba(28, 28, 30, 0.3);
		color     : rgba(28, 28, 30, 0.7);

		&:hover {
			border: 1px solid rgba(28, 28, 30, 0.7);
			color : rgba(28, 28, 30, 0.8);

		}

		&:active {
			border: 1px solid rgba(28, 28, 30, 1);
			color : rgba(28, 28, 30, 1);
		}

		&:focus:not(:active) {
			border    : 1px solid rgba(28, 28, 30, 0.3);
			box-shadow: 0px 0px 0px 3px var(--tw-btn-color);

		}
	}

	&-text {
		border-radius: 0;
	}
}

.smooth-wrapper {
	padding: inherit;
}

@layer components {
	.title {
		font-size  : 44px;
		line-height: 1.375;
		@apply font-alt uppercase font-bold;

		@media (max-width:theme('screens.lg')) {
			font-size: 36px;
		}

		@media (max-width:theme('screens.md')) {
			font-size: 30px;
		}

		@media (max-width:theme('screens.sm')) {
			font-size: 24px;
		}

		@media (max-width:theme('screens.xs')) {
			font-size: 22px;
		}
	}
}

.header {
	padding-top   : 24px;
	padding-bottom: 24px;
	background    : #fff;

	@media (max-width:theme('screens.lg')) {
		padding-top   : 12px;
		padding-bottom: 12px;
	}

	@media (max-width:theme('screens.sm')) {
		position      : fixed;
		top           : 0;
		left          : 0;
		width         : 100%;
		padding-top   : 8px;
		padding-bottom: 8px;
		z-index       : var(--zi-l1);

	}
}

.started {
	display       : flex;
	position      : relative;
	padding-top   : 50px;
	padding-bottom: 112px;
	background    : #fff;


	@media (max-width:theme('screens.lg')) {
		align-items   : flex-start;
		flex-direction: column;
		padding-top   : 0;
		padding-bottom: 60px;
	}

	@media (max-width:theme('screens.sm')) {
		padding-top   : 60px;
		padding-bottom: 32px;
	}

	&:before {
		content   : '';
		position  : absolute;
		top       : 0;
		right     : 0;
		width     : 400px;
		height    : 100%;
		background: linear-gradient(-90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
		z-index   : 5;

		@media (max-width:theme('screens.lg')) {
			top       : auto;
			bottom    : 0;
			width     : 100%;
			height    : 60%;
			background: linear-gradient(0deg, #fff 80%, rgba(255, 255, 255, 0) 100%);
		}
	}

	&-content {
		position      : relative;
		width         : 50%;
		padding-top   : 50px;
		z-index       : 6;
		// transform: translate3d(-50%, 0, 0);
		// opacity: 0;
		// will-change: transform, opacity;

		@media (max-width:theme('screens.lg')) {
			width      : 100%;
			padding-top: 32px;
		}
	}

	&-slider {
		position: relative;
		width   : 454px;
		height  : 596px;
		overflow: visible;

		padding-top   : 50px;
		pointer-events: none;

		@media (max-width:theme('screens.lg')) {
			order           : -1;
			max-width       : calc(100% - 20px);
			// margin-bottom: -296px;
			margin-left     : 20px;
			padding-top     : 0;
		}

		@media (max-width:theme('screens.md')) {
			width           : 354px;
			height          : 496px;
			// margin-bottom: -196px;
		}

		@media (max-width:theme('screens.sm')) {
			width           : 300px;
			height          : 400px;
			// margin-bottom: -150px;
		}

		@media (max-width:theme('screens.xs')) {
			width           : 240px;
			height          : 300px;
			// margin-bottom: -100px;
			margin-left     : 10px;
		}

		.swiper-slide:not(.swiper-slide-prev) {
			transition-delay: .5s !important;

		}
	}

	&-round {
		position       : absolute;
		bottom         : -20px;
		left           : -120px;
		width          : 240px;
		height         : 240px;
		backdrop-filter: blur(4px);
		border-radius  : 50%;
		z-index        : 3;
		will-change    : transform;

		@media (max-width:theme('screens.lg')) {
			display: none;
		}
	}
}

// body:not(.loaded) .started-round {
// 	position: fixed;
// 	left: calc(50% - 120px);
// 	top: calc(50% - 120px);
// 	z-index: var(--zi-l7);
// }



.qwiz {


	&-content {
		@media (min-width:theme('screens.md')) {
			background     : radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(140, 140, 140, 0.4) 0%, rgba(140, 140, 140, 0.1) 100%);
			backdrop-filter: blur(4px);
			box-shadow     : 0px 20px 40px rgba(0, 0, 0, 0.05);
		}
	}

	&-item-image {
		&::after {
			content   : "";
			position  : absolute;
			inset     : 0;
			visibility: hidden;
			opacity   : 0;
			transition: 0.2s ease-in;
			background: linear-gradient(180deg, rgba(246, 159, 95, 0) 47.18%, #DB7A30 100%), rgba(0, 0, 0, 0.5);
		}

	}

	&-item {
		@media(hover) {
			&:hover &-image::after {
				visibility: visible;
				opacity   : 0.6;
			}
		}

	}
}

.services {
	&-item {
		display       : flex;
		align-items   : flex-start;
		flex-direction: column;
		position      : relative;
		height        : 300px;
		overflow      : hidden;
		overflow      : hidden;
		background    : #121212;

		&-content {
			display       : flex;
			align-items   : flex-start;
			flex-direction: column;
			flex-grow     : 1;
			position      : relative;
			width         : 100%;
			background    : linear-gradient(81.35deg, rgba(220, 94, 0, 0.4) 0%, rgba(220, 94, 0, 0) 58%);
			z-index       : 2;

			@media (max-width:theme('screens.sm')) {
				background:
					linear-gradient(81.35deg, rgba(220, 94, 0, 0.4) 0%, rgba(220, 94, 0, 0) 58%),
					linear-gradient(81.35deg, rgba(18, 18, 18, 0.6) 30%, rgba(220, 94, 0, 0) 100%);

			}
		}

		&-img {
			position   : absolute;
			right      : 0;
			bottom     : 0px;
			will-change: transform;

			@media (max-width:theme('screens.md')) {
				height: 100%;
			}

			img {
				@media (max-width:theme('screens.md')) {
					height    : 100%;
					object-fit: cover;
				}
			}
		}
	}
}

.credits {
	background: #EAD3C3;

	&-image {
		width    : 476px;
		min-width: 476px;

		@media (max-width:theme('screens.lg')) {
			min-width: 0;
			max-width: 100%;
		}
	}
}


.works {
	background: #F1EDEB;

	&-tabs {
		display              : grid;
		grid-template-columns: 1fr 1fr 1fr;
		margin-bottom        : 40px;
		border-bottom        : 2px solid #E9D2C2;
		position             : relative;

		&-plate {
			position  : absolute;
			bottom    : -1px;
			height    : 2px;
			background: theme('colors.primary.DEFAULT');
			transition: .2s ease;
		}
	}

	&-tab {
		display        : flex;
		align-items    : center;
		justify-content: center;
		gap            : 18px;
		font-size      : 24px;
		font-weight    : 600;
		color          : theme('colors.black / 60%');
		padding-bottom : 20px;
		padding-top    : 20px;
		cursor         : pointer;

		@media (max-width:theme('screens.sm')) {
			font-size: 16px;
			gap      : 16px;
		}

		@media(hover) {
			&:hover {
				background: theme('colors.primary.DEFAULT / 10%');
			}
		}

		.icon {
			font-size: 40px;

			@media (max-width:theme('screens.sm')) {
				font-size: 20px;
			}
		}
	}

	&-item {
		&-video {
			display        : flex;
			align-items    : center;
			justify-content: center;
			position       : absolute;
			bottom         : 0;
			left           : 0;
			width          : 100%;
			background     : url("../img/works-item-sign.jpg") no-repeat center / cover, theme('colors.primary.DEFAULT');

			&-btn {
				position: static;

				&::before {
					content : '';
					position: absolute;
					inset   : 0;
				}
			}
		}

		&-image {
			display: block;
		}
	}
}

.equipment {
	background: linear-gradient(81.35deg, rgba(220, 94, 0, 0.4) 0%, rgba(220, 94, 0, 0) 58.05%), #121212;
	color     : #fff;

	&-item {
		background: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(140, 140, 140, 0.18) 0%, rgba(140, 140, 140, 0.12) 100%);
		box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
	}
}

.steps {
	&-item {
		box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	}
}

.features {
	background: linear-gradient(81.35deg, rgba(220, 94, 0, 0.4) 0%, rgba(220, 94, 0, 0) 58.05%), #121212;
	color     : #fff;

	&-block {
		padding   : 48px;
		background: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(140, 140, 140, 0.18) 0%, rgba(140, 140, 140, 0.12) 100%);

		@media (max-width:theme('screens.lg')) {
			align-items   : center;
			flex-direction: column;
		}

		@media (max-width:theme('screens.md')) {
			padding: 24px;
		}
	}

	&-img {
		position    : relative;
		width       : 490px;
		min-width   : 490px;
		margin-top  : calc(-48px - 35px);
		margin-right: 52px;

		@media (max-width:theme('screens.lg')) {
			min-width    : 0;
			max-width    : 100%;
			margin-right : 0;
			margin-bottom: 32px;
		}

		&:before {
			content      : '';
			position     : absolute;
			bottom       : -6px;
			left         : 10%;
			width        : 90%;
			height       : 11px;
			background   : rgba(0, 0, 0, 0.7);
			border-radius: 50%;
			filter       : blur(7.5px);
		}
	}

	&-button {
		position: absolute;

		&-1 {
			top : calc(16 / 376 * 100%);
			left: calc(40 / 490 * 100%);
		}

		&-2 {
			top : calc(56 / 376 * 100%);
			left: calc(295 / 490 * 100%);
		}

		&-3 {
			top : calc(138 / 376 * 100%);
			left: calc(118 / 490 * 100%);
		}

		&-4 {
			bottom: calc(24 / 376 * 100%);
			left  : calc(43 / 490 * 100%);
		}

		&-5 {
			bottom: calc(43 / 376 * 100%);
			left  : calc(355 / 490 * 100%);

			@media (max-width:theme('screens.sm')) {
				left: calc(275 / 490 * 100%);
			}
		}

		&-text {
			display        : flex;
			align-items    : center;
			justify-content: center;
			position       : absolute;
			top            : calc(50% - 24px);
			left           : 0;
			height         : 48px;
			padding-right  : 18px;
			padding-left   : 38px;
			transition     : .5s cubic-bezier(0.510, -0.015, 0.000, 1.330);
			background     : #FFFFFF;
			border-radius  : 0 25px 25px 0;
			color          : theme('colors.gray.DEFAULT');
			white-space    : nowrap;

			@media (max-width:theme('screens.sm')) {
				top         : calc(50% - 20px);
				height      : 40px;
				padding-left: 30px;
				font-size   : 14px;
			}

			&:before {
				content      : '';
				position     : absolute;
				inset        : 5px;
				border-radius: inherit;
				@apply border border-primary;
			}
		}

		&-text-wrap {
			position      : absolute;
			top           : 0;
			left          : 50%;
			width         : 500px;
			height        : 100%;
			overflow      : hidden;
			pointer-events: none;
		}

		&:not(.tab-active) &-text {
			transform: translateX(-100%);
		}

		.icon {
			transition: .25s cubic-bezier(0.510, -0.015, 0.000, 1.330);
		}

		&.tab-active .icon {
			transform: rotateZ(45deg);
		}
	}
}

.reviews {
	background: #fff;

	&-item {
		&-play {
			position: absolute;
			top     : calc(50% - var(--size) / 2);
			left    : calc(50% - var(--size) / 2);
		}

		&-dude {
			display    : flex;
			align-items: center;
			background : linear-gradient(274.27deg, #FF6218 0%, #FB8110 24.5%, #FF810D 67.7%, #FF6218 100%);
			color      : #fff;

			@media (max-width:theme('screens.xs')) {
				bottom: 6px;
				left  : 6px;
				width : calc(100% - 12px);
			}
		}
	}
}

.questions {
	background: #F1EDEB;

	&-item {
		&-top {
			position: relative;

		}

		&-top:not(.toggle-active) &-btn {
			filter: grayscale(1);
		}

		&-btn .icon {
			transition: .25s cubic-bezier(0.510, -0.015, 0.000, 1.330);
		}

		&-top.toggle-active &-btn .icon {
			transform: rotateZ(-135deg);
		}
	}
}


.contacts {
	padding-bottom: 0 !important;

	@media (max-width:theme('screens.sm')) {
		padding: 0;
	}

	&-block {
		@media (min-width:theme('screens.sm')) {
			box-shadow: 2px 2px 31px 2px rgba(213, 91, 34, 0.3);
		}
	}

	&-map {
		width : 100%;
		height: 376px;

	}

	&-content {
		background: linear-gradient(274.27deg, #FF6218 0%, #FB8110 24.5%, #FF810D 67.7%, #FF6218 100%), #353535;
		color     : #fff;

		@media (max-width:theme('screens.lg')) {
			display              : grid;
			grid-template-columns: 1fr 1fr;
			grid-gap             : 20px;
			padding-right        : 32px;
			padding-left         : 32px;
		}

		@media (max-width:theme('screens.sm')) {
			grid-template-columns: 1fr;
			padding-right        : 15px;
			padding-left         : 15px;
		}
	}
}

.dialog-form {
	width    : 960px;
	max-width: 100%;

	@media (max-width:theme('screens.lg')) {
		width: 600px;
	}

	&-img {
		width      : 458px;
		min-width  : 458px;
		margin-left: 44px;
	}
}

.dialog-policy {
	width    : 1215px;
	max-width: 100%;
}

.dialog-work {
	width    : 1170px;
	max-width: 100%;

	@media(max-width:1024px) {
		flex-direction: column;
		width         : 500px;
	}


	&-slider {
		width       : 450px;
		min-width   : 450px;
		margin-right: 52px;
		margin-left : 0;

		@media(max-width:1024px) {
			min-width    : 0;
			max-width    : 100%;
			margin-right : 0;
			margin-bottom: 32px;

		}

		@media (max-width:theme('screens.sm')) {
			margin-bottom: 0;
		}
	}
}

.dialog-qwiz {
	width    : 960px;
	max-width: 100%;

}

.qwiz {
	&-field {
		display : block;
		position: relative;

		input {
			display: none;
		}

		&-target {
			display      : flex;
			align-items  : center;
			position     : relative;
			min-height   : 72px;
			padding      : 15px;
			padding-left : 56px;
			transition   : .2s ease;
			background   : rgba(54, 54, 54, 0.9);
			border-radius: 8px;
			color        : #fff;
			cursor       : pointer;
			user-select  : none;

			&:active {
				transform: translateY(2px);
			}


			&:hover {

				background: linear-gradient(theme('colors.primary.DEFAULT / 50%'), theme('colors.primary.DEFAULT / 50%')), rgba(54, 54, 54, 0.9);
			}

			&:before {
				content      : '';
				position     : absolute;
				top          : 24px;
				left         : 16px;
				width        : 24px;
				height       : 24px;
				background   : #FFFFFF;
				border-radius: 50%;
			}

			&:after {
				content      : '';
				position     : absolute;
				top          : 31px;
				left         : 23px;
				width        : 10px;
				height       : 10px;
				opacity      : 0;
				transform    : scale(0);
				transition   : .25s cubic-bezier(0.510, -0.015, 0.000, 1.330);
				background   : linear-gradient(theme('colors.primary.DEFAULT / 80%'), theme('colors.primary.DEFAULT / 80%')), rgba(54, 54, 54, 0.9);
				border-radius: 50%;
			}

			.ripple {
				background: #fff;
			}
		}

		input:checked~&-target {
			background: #D67B3C;

			&:after {
				opacity  : 1;
				transform: scale(1);
			}
		}
	}

	&-material {
		cursor: pointer;

		input {
			display: none;
		}

		input:checked~&-target &-target-image:after {
			content : '';
			position: absolute;
			inset   : 0;
			background:
				url("../img/circle-check.png") no-repeat center/ auto,
				linear-gradient(180deg, rgba(246, 159, 95, 0) 47.18%, rgba(219, 122, 48, 0.6) 100%), rgba(0, 0, 0, 0.5);
			z-index: 2;
		}
	}
}

.dialog-qwiz-slider {
	.swiper-slide {
		align-self: start;
	}
}

.footer-bottom {
	display        : flex;
	align-items    : center;
	justify-content: space-between;
	padding-top    : 24px;
	padding-bottom : 24px;
	z-index        : 2;
	position       : relative;

	@media (max-width:theme('screens.sm')) {
		padding-left : 15px;
		padding-right: 15px;
	}
}

.footer-bottom-link {
	text-decoration      : underline;
	text-underline-offset: 4px;
}