.fancybox-custom-modal {
  .fancybox__slide {
    max-width: 100%;
    padding: 15px;
  }

  .fancybox__content,
  .fancybox__container {
    @apply text-gray;
  }

  .fancybox__content {
    max-width: 100%;
    padding: 0;
    background: none;

  }

  .carousel__button.is-close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 40px;
    height: 40px;
    padding: 0;
    background: rgba(28, 28, 28, 0.5);

    border-radius: 50%;

    @media (max-width:theme('screens.lg')) {
      top: 16px;
      right: 16px;
    }

    @media (max-width:theme('screens.sm')) {
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
    }

    svg {
      width: 16px;
      height: 16px;
      color: #fff;
      filter: none;

    }
  }
}